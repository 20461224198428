code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: 700 !important;
}

.card {
  border: none;
  /* -webkit-box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%); */
  /* box-shadow: 0 0.3rem 0.3rem rgb(0 0 0 / 15%); */
}

.detailscard {
  margin-top: 10px;
}

.detailscard {
  width: 110px;
  background: white;
  /* border: 1px solid lightgray; */
  padding: 5px;
  /* display: flex;
  align-items: center;
  flex-direction: column; */
  margin-right: 1rem;
}

.detailscard img {
  width: 100px;
  height: 100px;
}

.detailscard p {
  width: 100px;
  font-size: .7rem;
  margin-top: 10px;
}

.detailscard2 {
  margin-top: 10px;
}

.detailscard2 {
  background: #f3f3f3;
  /* width: 100%; */
  /* border: 1px solid lightgray; */
  /* padding: 5px; */
}

.detailscard2 table {
  background: #dcf1e4;
}

.detailscard2.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  /* border: 1px solid lightgray; */
}

.detailscard.card-body {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
  border: 1px solid lightgray;
}

li.list-group-item.detailspage {
  position: relative;
  display: block;
  padding: 0px !important;
  background-color: #fff;
  /* border: 1px solid rgba(0, 0, 0, 0.125); */
}

.selectstock {
  position: absolute;
  right: 10px;
}

.selectStock2 {
  position: absolute;
  right: 170px;
}

.selectStock3 {
  position: absolute;
  left: 140px;
}

/* //////////Select address/////////////// */

.section-title {
  /* text-transform: capitalize; */
  font-size: 25px;
  position: relative;
  padding-bottom: 0px;
}

.new-address-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 4px 9px;
  border-radius: 3px;
  float: right;
  margin: auto;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
}

.address-header h5 {
  font-size: 16px;
  margin-bottom: 15px;
}

.address-header {
  display: flex;
  align-items: baseline;
}

.address-header input {
  margin-right: 10px;
}

.checkbox input {
  margin-right: 10px;
}

.address-links {
  margin-top: 10px;
}

.address-links a {
  margin-right: 10px;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: transparent !important;
  border: none !important;
}

.checkout-bx {
  padding: 15px 0;
  border-bottom: 1px solid #f9f6f6;
}

.checkout-bx:last-child {
  border-bottom: none;
}

.checkout-bx hr:last-child {
  /* display: none; */
}

.checkout-img {
  width: 100%;
  /* height: 100px; */
  aspect-ratio: 1/1;
  margin: auto;
  border-radius: 0.3rem;
}

.checkout-details p {
  font-size: 20px;
}

.checkout-details h6 {
  margin-bottom: 5px;
  margin-top: 15px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
  cursor: initial !important;
}

.grand-total {
  font-size: 17px;
}

.grand-total td {
  padding-top: 20px !important;
  font-weight: 300;
}

.checkout-btn {
  background: #ee1b2f;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 10px 20px;
  margin-top: 20px;
  display: inline-block;
  box-shadow: 2px 2px 10px rgb(10 10 10 / 11%);
  border-radius: 3px;
}

.checkout-btn:hover {
  color: #ee1b2f;
  background: white;
  border: 1px #ee1b2f solid;
  border-radius: 3px;
}

.boxes {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #eeeeee;

  border-width: 1px;
  box-sizing: border-box;
  border-radius: 5px;
}

.address-box {
  font-size: 14px;
}

.address-box input {
  margin-right: 10px;
}

.order-summary {
  /* border: 1px solid #EAEAEA; */
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 45px;
  border-radius: 5px;
  /* background: #F7F7F7; */
  border: none;
  border: 1px solid #d9d9d9;
}

.order-summary.sticky-top {
  transition: .3s;
  z-index: 0 !important;
}

.order-summary h5 {
  font-size: 20px;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 10px;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.order-summary-table {
  font-size: 15px;
  width: 100%;
}

.order-summary-table tr td {
  padding: 8px 0;
}

.checkout-box {
  border: none;
}

.btnicons {
  width: 20px;
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
}

.address-card {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  /* box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%); */
  border: 1px solid var(--theme-color-dark);
}

/* //////////Select address end /////////////// */

/* ===========Login.js=========== */

.rgbox-bottom {
  position: relative;
}

.rgbox-bottom p::before {
  border-bottom: 1px solid #767676;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  bottom: 10px;
  margin: 0 auto;
}

.rgbox-bottom p label {
  background-color: #fff;
  margin-bottom: 0px;
  color: #767676;
  position: relative;
  padding: 0px 5px;
}

.but button {
  margin-top: 10px;
  width: 100%;
}

.cart-box .account-dropdown-content {
  position: absolute;
  background-color: #fff;
  visibility: hidden;
  opacity: 0;
  min-width: 180px;
  box-shadow: 0 2px 3px 0 rgb(34 36 38 / 15%);
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 20px 20px 20px 30px;
  z-index: 9999;
  margin-top: 15px;
  transform: translateY(-2em);
  transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s,
    z-index 0s linear 0.01s;
}

.but-button {
  margin-top: 10px;
  width: 100%;
  background: white;
  color: #50b05d;
  border: 1px solid #50b05d;
}

.but-button :hover {
  background: #50b05d !important;
  color: white !important;
  border: 1px solid #50b05d !important;
}

/* ===========Login.js end=========== */

.header-hover:hover {
  color: #50b05d;
  text-decoration: none;
}

.detailscard .card-body:hover {
  box-shadow: 10px 10px 15px 0 rgb(7 28 31 / 10%);
}

.tab {
  overflow: hidden;
  border: 1px solid #fff;
  margin-top: 10px;
  border-top-left-radius: 10px;
  margin-left: 20px;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 10px 16px;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button {
  background-color: var(--theme-color-dark);
  color: white;
  border-top-right-radius: 10px;
  height: 40px;
}

.footercart {
  width: auto !important;
  /* border: 1px solid #f7f7f7; */
  background-color: #fafafa;
  border-radius: 10px;
  box-shadow: none;
  margin-right: 2.2rem;
}

.securepay {
  margin-left: 22px;
}

.footer-section {
  margin-bottom: 50px;
  margin-top: 40px;
}

.footer-bg {
  background: linear-gradient(#fafafa5a,
      #fafafa5a),
    url(./assets/img/footer-bg.png);
}

.CustomerPara span {
  font-weight: 300;
}

.footer-section2 {
  margin-bottom: 25px;
}

.Vertical-wrap {
  max-width: 185px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 5px;
}

.Vertical-wrap h6 {
  font-size: 16px;
}

.Vertical-wrap .item {
  /* margin-bottom: 30px; */
  transition: all ease-in-out 0.3s;
}

.Vertical-wrap .item .services-icon {
  transition: transform 0.3s;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
}

.Vertical-wrap .item p {
  width: 100px;
  font-size: 0.9rem;
  transition: transform 0.8s;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2rem;
}

.Vertical-wrap .item:hover .services-icon {
  transform: translateY(-10%) scale(1.15);
}

.Vertical-wrap .item:hover p {
  transform: translateY(-18%);
}

.Vertical-wrap .item {
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
  padding: 15px 10px;
  /* -webkit-box-shadow: 5px 0px 5px 0px rgba(148, 146, 245, 0.15); */
  /* box-shadow: 5px 0px 5px 0px rgba(148, 146, 245, 0.15); */
  border-radius: 5px;
  /* margin-bottom: 30px; */
  height: 180px;
}

.services-icon {
  width: 90px;
  height: auto;
  margin-bottom: 10px;
}

.swiper-grid>.swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-grid-column>.swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.store-location-links a svg {
  width: 30px;
  height: 30px;
  /* background-color: var(--theme-color-dark); */
  color: var(--theme-color-dark);
  border-radius: 0.275rem;
  margin-right: 0.5rem;
  padding: 3px;
  transition: 0.3s;
}

.store-location-links a:hover svg {
  transform: scale(0.8);
}

.video-container {
  width: 100%;
  z-index: -1;
  height: 100vh;
  background: url(../src/assets/img/Trystcomingsoonbanner.jpg);
}

.selected-category {
  text-decoration: none;
  background-color: #dee1e47d;
}

.productFilters.dropdown-item:active {
  background-color: #f8f9fa !important;
  border: none !important;
}

.offer-label {
  position: absolute;
  right: 0;
  top: 0;
  padding: 8px;
  font-size: .8rem !important;
  border-radius: 0 5px 0 5px;
  background: var(--theme-color-dark);
  color: var(--theme-color-white);
}

.veg-nonveg-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-self: center;
  padding: 2px;
  background-color: var(--theme-color-white);
}

.veg-nonveg-icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transform: scale(1) !important;
}

.order-status {
  background-color: var(--theme-color-dark);
  color: #fff;
  border-radius: 5px;
  position: absolute;
  top: 17px;
  right: 16px;
  /* font-size: 12px; */
  padding: 2px 5px;
}

.order-item-list {
  overflow-y: auto;
  max-height: 130px;
}

.order-item-list {
  padding-right: 1rem;
  scrollbar-width: auto;
  scrollbar-color: var(--theme-color-dark) #ffffff;
}

.order-item-list::-webkit-scrollbar {
  width: 4px;
}

.order-item-list::-webkit-scrollbar-track {
  background: #ffffff;
}

.order-item-list::-webkit-scrollbar-thumb {
  background-color: var(--theme-color-dark);
  border-radius: 5px;
  border: 3px solid var(--theme-color-dark);
}

.order-item-list div {
  /* width: 100%; */
}

.order-item-image {
  width: 60px;
  height: auto;
  border: 1px solid #e9e9eb;
}

@media (max-width: 992px) {
  .video-container {
    background: url(../src/assets/img/comingsoonmobilesize.png);
    background-size: 130% 100vh;
    background-repeat: no-repeat;
    background-position-x: -100px;
  }

  .order-item-list {
    max-width: 100%;
  }

  .view-invoice-btn {
    position: relative;
    bottom: auto;
    right: auto;
    display: flex;
    gap: 5px;
    margin-top: 15px;
  }

  .address-card {
    /* box-shadow: 0 0 10px 0 rgb(154 161 171 / 15%); */
  }
}

@media (max-width: 568px) {
  .vertical-section .p-images img {
    height: 144px !important;
  }

  .video-container {
    background-size: 130% 100vh;
    background-position-x: -50px;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 731px) {

  /* =======footer responsive== */
  .footercart {
    margin-bottom: 10px;
  }

  .footerCard img {
    width: 30%;
    height: 80px;
    margin-top: 10px;
  }

  .securepay {
    margin-left: 5px !important;
  }
}

@media screen and (max-width: 1024px) {
  .footerCard {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  .footerCard img {
    margin: 0;
  }

  .footerCard .CustomerPara {
    margin: 0;
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  /* .footercart {
    width: 100% !important;
  } */
}

@media (max-width: 768px) {
  .img-fluid {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    padding: 0 !important;
  }

  .image-Grid img {
    margin: 0 !important;
  }

  .footer .add {
    width: 100% !important;
  }

  .follow-us-section {
    padding-top: 80px;
    width: 100% !important;
  }

  .follow-us-section .contact-list {
    margin: 0 !important;
    margin-bottom: 15px !important;
  }
}

@media (max-width: 1024px) {
  .footerfollowus {
    flex-direction: column !important;
  }

  .footerfollowus .social-icons {
    margin: 0 !important;
  }

  .footer-margin {
    /* margin-left: 7px; */
    /* margin-bottom: -5px !important; */
  }
}

@media (max-width: 575.98px) {
  .footerCard {
    gap: 0;
    padding: 1rem;
  }

  .footerCard img {
    width: 70px !important;
    height: 70px !important;
    margin-top: 10px !important;
  }

  .follow-us-section {
    padding-top: 0;
    width: 100% !important;
  }

  .services-icon {
    width: 90px;
  }
}

.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 15px;
  font-weight: 400;
  height: 50px;
}

/* By Vinay Maheshwari */
.coupon {
  border: 1px dashed #a9abb2;
  color: #535665;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 0 15px;
  margin: 10px 0 15px;
  font-weight: 400;
  height: 50px;
}

.coupon-card {
  padding: 1rem;
  border: 1px solid #e9e9eb;
  color: #3e4152;
}

.modal-header {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 3rem;
  border-bottom: none !important;
}

.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
}

.modal-body {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem 3rem;
}

.close {
  float: right;
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.coupon-more {
  color: #5d8ed5;
  font-size: 1rem;
  cursor: pointer;
}

.apply-coupon-btn {
  width: 100%;
  text-decoration: none;
  display: inline-block;
  text-align: center;
  border: none;
  line-height: 50px;
  font-size: 15px;
  height: 50px;
  padding: 0 2rem;
  color: var(--theme-color-dark);
  letter-spacing: 0;
  border: 1px solid var(--theme-color-dark);
  margin-top: 20px;
  line-height: 36px;
  width: auto;
  height: 42px;
  padding: 0 15px;
  outline: none;
  min-width: 120px;
  background: #fff;
}

/* .anticon {
  display: none !important;
} */
.header.ant-skeleton-image {
  height: 70px !important;
}

button:focus {
  outline: none;
  outline: none;
}

.coupon-card h5 {
  /* background-color: wheat; */
  /* width: 50%; */
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
}

.coupon-terms-condition {
  font-size: 0.92rem;
  color: #686b78;
}

.removeCoupon {
  float: right;
}

.removeCoupon :hover {
  color: #ee1b2f !important;
}

.coupon-text span {
  color: green;
}

.MobileBannerTextVinay {
  position: absolute;
  top: 45%;
  padding-left: 20px;
}

button.btn.btn-success.mobilebannerinnerbtn4 {
  width: 96px !important;
  background: rgb(0, 0, 0) !important;
  border: rgb(0, 0, 0) !important;
  font-size: 15px !important;
  border-radius: 5px;
}

.vertical-product-height {
  /* min-height: 100vh !important; */
}

.modal-dialog-centered {
  justify-content: center !important;
}

.modal-dialog-centered .login-box {
  padding: 0 !important;
  padding-bottom: 1rem;
  width: 100%;
  transform: translateY(-20px);
}

:where(.css-dev-only-do-not-override-nllxry).ant-collapse>.ant-collapse-item-active:first-child {
  border-bottom: none !important;
}

@media (max-width: 1190px) {
  .item .product-box img {
    height: 264px;
  }
}

@media (max-width: 1025px) {
  .item .product-box img {
    height: 225px;
  }
}

@media (max-width: 992px) {
  .item .product-box img {
    height: 163px;
  }

  .searchbar-offcanvas .item .product-box img {
    height: 112px;
  }

  .footercart {
    margin: 0;
  }

  .searchbar-offcanvas .item .product-box {
    margin: 0 .5rem;
  }

  .mobile-searchbar .item .product-box img {
    width: 100%;
    height: 100%;
  }

  /* .mobile-searchbar .product-title2 {
    height: 95px;
  } */

  .product-card-section .product-box .content-box {
    height: 100%;
    padding: .5rem !important;
  }

  .searchbar-offcanvas .product-box .p-images {
    height: 120px !important;
  }
}

.vertical-sekelton-img {
  width: 120px !important;
  height: 120px !important;
}

.vertical-sekelton-text {
  width: 120px !important;
}

.Skeleton-vertical-wrap {
  width: 150px;
}

.mobile-banner-skeleton {
  height: 261px !important;
}

.ratings-skeleton-image.ant-skeleton-image {
  width: 200px !important;
  height: 200px !important;
  margin-right: 2rem;
}

@media (max-width:992px) {
  .vertical-sekelton-img {
    width: 100px !important;
    height: 100px !important;
  }

  .vertical-sekelton-text {
    width: 100px !important;
  }

  .Skeleton-vertical-wrap {
    width: 100px;
  }

  .Mobile-logo-skeleton {
    height: 49px !important;
  }
}

@media (max-width:568px) {
  .modal-body {
    padding: 1rem;
  }
}

.productlisting-heading {
  text-transform: capitalize;
}

.listingpage-height {
  height: 50vh;
}


.theme-btn-disabled {
  background: grey;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1.1rem;
  opacity: .5;
  cursor: not-allowed;
  color: #fff !important;
}

.cart-accordian-icons {
  font-size: 25px;
}

.cartcheckout-accordion .ant-collapse-header .cart-accordian-icons {
  padding: 5px;
  background-color: #f5f3f0;
  border-radius: 50%;
  transition: .4s all;
  color: var(--theme-color-dark);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: .5rem;
}

.cartcheckout-accordion .ant-collapse-item-active .ant-collapse-header .cart-accordian-icons {
  background-color: var(--white-color);
}

.order-summary-table svg {
  margin-right: 10px;
}


/* For Inner Banner */
@media (max-width:500px) {
  .mobile-imgae-space {
    padding: calc(1.5rem * 0.5) !important;
    padding-top: 0 !important;
  }
}