.product-reviews{

      padding: 15px 0 0px;
}
/* .product-reviews{
  
    display: flex;  */
    /* padding-right: 2rem; */
    /* padding: 2rem 2rem 1.5rem 1rem; */
    /* border-bottom: 1px solid rgba(101, 119, 134, 0.22); */
/* } */

.overall-ratings{
  display: flex;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  border-right: 2px solid rgba(101, 119, 134, 0.22);
}
.review-card{
  padding: 1rem;
  margin: 1rem;
  position: relative;
  box-shadow: 0 0 10px #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 5px;
}
.review-card .css-rwwnk2,
.review-card .userInfoSection,
.review-card .css-1v6g5ho{
  padding: 0;
}

.review-card .css-1n0nrdk{
  height: 60px;
  overflow-y: auto;
  font-size: .9rem;
  padding-right: 10px;
}
.review-card .css-1n0nrdk::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.review-card .css-1n0nrdk::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6c6a6a;
}
.review-card .UserInformationProductReviews{
  display: flex !important;
  width: 100% !important;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
}
.review-card .verified-buyers-icon{
  display: flex;
  align-items: center;
}
.review-card .verified-buyers-icon img{
  width: 15px;
  height: 15px;
  margin: 0 10px;
}
 .overall-ratings > img{
  width: 200px;
  height: 200px;
  margin-right: 2rem;
}

.ratings{
      font-size: 36px;
    
    letter-spacing: -0.3px;
    line-height: 50px;
    margin-right: 0.5rem;
}
.ratings-exact{
      font-size: 36px;
  
    line-height: 40px;
    letter-spacing: -0.3px;
}

.ratings-upto{
      font-size: 36px;
  
    line-height: 40px;
}

.ratings-static{
  font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    display: block;
    margin-top: 0.5rem;
}

.ratings-verified{
  line-height: 16px;
    flex: 2 1 0%;
}

.verified-upto{
  font-size: 13px;
  
    line-height: 20px;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.72);
}

.writereview{
  font-size: 14px;
 
    line-height: 20px;
    letter-spacing: 0px;
    font-stretch: normal;
    font-style: normal;
    color: rgba(0, 19, 37, 0.72);
    padding-bottom: 0.5rem;
}

.customer-reviews{

    padding: 25px 75px;

}

.customer-reviews-Description{
      display: flex;
    width: 100%;
    /* border-bottom: 1px solid rgba(101, 119, 134, 0.12); */
    padding: 25px 55px;
}

.userInfoSection{
  display: flex;
    padding: 0px 0px 16px;
}

.userImageIcon{
  width: 48px;
    height: 48px;
    border-radius: 50%;
    vertical-align: top;
    display: inline-block;
}
.verified-buyers-icon{
  font-size: 14px;
   
    line-height: 16px;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.52);
}

.userNameReviews{
  font-size: 17px;
   
    line-height: 20px;
    letter-spacing: -0.1px;
    display: block;
    color: rgba(0, 19, 37, 0.72);
    margin: 0.25rem 0px;
}

.UserInformationProductReviews{
  display: inline-block;
    margin-left: 10px;
    width: 165px;
    word-break: break-word;
}

.star-ratings{
  display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-right: 0.5rem;
}

.css-rwwnk2 {
    background-color: white;
    font-size: 16px;
   
    line-height: 24px;
    letter-spacing: -0.1px;
    padding: 0.5rem 1rem;
}
.css-1at2wjx {
    background-color: rgb(0, 137, 69);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.1px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
}

.css-1at2way {
    background-color: #c30f0f;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.1px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    color: white;
    border-radius: 4px;
    padding: 3px 5px;
}

.css-w0v4um {
    display: inherit;
    margin-left: 0.25rem;
}

.css-177e1lt {
    font-size: 17px;
    line-height: 16px;
    letter-spacing: 0px;
    float: right;
    color: rgba(0, 19, 37, 0.52);
    margin-top: 0.25rem;
}

.css-tm4hnq {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.1px;
    color: rgba(0, 19, 37, 0.92);
    padding-bottom: 0.5rem;
    margin-top: 0.5rem;
}
.css-1n0nrdk {
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0px;
    color: rgba(0, 19, 37, 0.64);
}

.css-1v6g5ho {
    padding: 0px 1rem;
}

.css-1rjb1jp {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1px;
    background-color: rgb(255, 255, 255);
    text-align: center;
}
.css-1xv8iu0 {
    padding: 1rem 0px;
    display: block;
    color: rgba(0, 19, 37, 0.92);
    text-decoration: none;
    cursor: pointer;
}
.css-1a3bom8 {
    margin-right: 0.5rem;
    color: var(--theme-color-dark);
}

.box{
  display: flex;;
  /* justify-content: center; */
  align-items: center;
}

.productForReview{
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
}

.box-container{
  width: 768px;
  padding-top: 0px !important;
  padding: 40px;
}

.create-review{
     text-align: left;
    padding-bottom: 25px;
    padding-top: 25px;
}


.product-reviewImage{
     /* display: flex;
     align-items: flex-end; */
    /* width: 30%; */
}

.variantNameReview{
  text-align: left;
    width: 70%;
}

.over-all-rating{
  display: flex;
  justify-content: space-between;
}

.container-clear{
  text-align: right;
  cursor: pointer;
  color: var(--theme-color-dark);
}
.headline{
  margin-top: 10px;
}

.submit-review-button{
  width: 30%;
  background: var(--theme-color-dark);
  color: #fff;
}

.submit-review{
  text-align: right;
}

.star-review input[type="radio"] { 
  display: none;
}
.star {
  cursor: pointer;
  transition: color 200ms;
}

.all-review-scroll{
  height: 700px;
  overflow-x: scroll;
}

.all-review-scroll::-webkit-scrollbar {
  display: none;
}

.product-reviews-all{
     padding: 0px 0 0px;
}

@media (min-width: 320px) and (max-width: 480px) {


.product-reviews-all{
     padding: 100px 0 0px;
}

.customer-reviews-Description {
    display: flex;
    width: 100%;
    border-bottom: 1px solid rgba(101, 119, 134, 0.12);
    padding: 25px 0px;
}

.overall-ratings {
    display: flex;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem 0.5rem;
    border-right: none;
}

}

.rating-spinner{
    text-align: center;
    padding: 60px 0px;
    color: var(--theme-color-dark);
}
