.pincode-modal .modal-dialog{
    width: fit-content !important;
}
.pincode-modal .location-icon {
    font-size: 4rem;
    margin-bottom: 1rem;
}

.pincode-modal .ant-select-selector {
    border-radius: 5px;
}