/* .App {
    text-align: center;
  }
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
  }
  .App-header {
    background-color: #282C34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  .App-link {
    color: #61DAFB;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  } */

.search-result ul .list-group-item {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.search-result .searchProduct {
  display: flex;
  align-items: end;
  padding: 0.5rem 0;
}
.search-result .list-group-item h5 {
  font-size: 12px;
  margin-left: 15px;
}
td {
  list-style: none;
  padding: 15px 15px 10px 10px;
  border-bottom: 1px solid #ccc;
  width: auto;
  cursor: pointer;
  align-items: center;
}
td img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
#query {
  width: 100%;
  font-size: 15px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1);
}
#notebooks {
  background: white;
  position: relative;

  margin-top: 25px;

  border-radius: 0.3rem;
  box-shadow: inset 1px 1px 0 white;

  z-index: 999;
}

tbody {
  margin: 0 auto;
  padding: 0;
  max-height: 390px;
  overflow-y: auto;
  border-left: none;
  border-right: none;
}
#notebooks span {
  display: block;
  position: flex;
  background: var(--theme-color-dark);
  bottom: -35px;
  width: 433px;
  border-radius: 0 0 5px 5px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 1px rgba(255, 255, 255, 0.5);
}

/* .search-result .card-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.search-result .card-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--theme-color-dark);
} */
.search-result {
  position: absolute;
  top: 3rem;
  top: 3rem;
  left: 0;
  right: 0;
}

.input-menu-all {
  display: none;
}
.open-menu-all {
  position: relative;
  margin: 0;
}
.all-category-list {
  position: absolute;
  z-index: 9999;
  display: none;
  width: 250px;
}
.open-menu-all .input-menu-all:checked ~ .all-category-list {
  display: block;
  transition: all 0.5s ease;
}
.nav-container .nav {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.category {
  margin: 0 20px;
}
.open-search {
  flex: auto;
  margin: 0 20px;
}
.mob-logo {
  display: none;
}
.input-open-search {
  display: none;
}
.open-search i {
  display: none;
}
@media (max-width: 991px) {
  .all-category-list {
    position: absolute;
    display: block;
    z-index: 9999;
    left: -280px;
    width: 250px;
  }
  .open-menu-all .input-menu-all:checked ~ .all-category-list {
    display: block;
    left: -20px;
    transition: all 0.5s ease;
  }
  .open-menu-all .input-menu-all:checked ~ .categories-btn .fa-bars:before {
    content: "\f00d";
  }
  .categories-btn span {
    display: none;
  }
  .mob-logo {
    display: block;
  }
  .open-search i {
    display: block;
  }

  .logo1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25px;
  }
  .logo {
    height: auto;
    width: 110px;
  }
  #query {
    padding: 5px;
  }
  .category {
    margin: 0;
    order: -1;
  }
  .open-search .input-open-search:checked ~ .search-box1 {
    display: block;
  }
  .categories-btn {
    background: var(--theme-color-dark);
    color: #fff;
    width: 100%;
    display: block;
    text-align: left;
    padding: 10px 15px;
  }
  .open-search {
    margin: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
  }
  .search-box1 {
    display: none;
    position: absolute;
    width: 100%;
    left: 0;
    top: 59px;
  }
  .open-search i {
    font-size: 25px;
  }
  .account-icon {
    font-size: 30px !important;
    border: 1px solid #000000;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .account-options li {
    list-style-type: none;
    margin-right: 23px;
    position: relative;
    display: flex;
    align-items: center;
  }
  .categories-btn i {
    margin-right: 0px;
    width: 15px;
    height: 15px;
  }
  .user-login {
    display: none;
  }
}
.searchProduct {
  margin-left: 15px;
  margin-top: -5px;
}
.Search_categoryname {
  font-size: 0.9rem;
  color: #00000075;
}
.Search_variantName {
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
}
.search-btn {
  position: absolute;
  right: 19px;
  font-size: 1.8rem;
  padding: 0;
  background: transparent;
  color: var(--theme-color-dark);
}
.search-btn:hover svg {
  color: var(--theme-color-dark) !important;
}
p.searchPriceinfo {
  position: absolute;
  right: 207px;
}
.search-weight {
  position: absolute;
  right: 350px;
  top: 30px;
}

.nav-pincode-box svg {
  cursor: pointer;
  font-size: 1.7rem;
  margin-right: 0.5rem;
}
.nav-pincode-box span {
  /* height: 100%; */
  border: 1px solid #d1d1d1;
  padding: 9px 10px;
  border-radius: 5px;
  width: 70%;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 1.5px;
  font-size: 0.8rem;
}
.mobile-pincode-icon {
  position: fixed;
  left: 1rem;
  bottom: 5rem;
  font-size: 1.5rem;
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 50%;
  z-index: 99 !important;
  color: var(--theme-color-dark);
  background: #fff;
  border: none;
  box-shadow: 0px 0px 10px rgba(148, 146, 245, 0.15);
  animation: bounce 1s ease infinite alternate;
  cursor: pointer;
}

@keyframes bounce {
  0% {
    /* text-shadow: 0 5px 0 #ccc, 0 2px 3px rgba(0, 0, 0, 1); */
  }
  100% {
    transform: translateY(-20px);
    /* text-shadow: 0 50px 0 black, 0 0px 20px rgba(0, 0, 0, 0.8); */
  }
}
.coupon-modal .modal-title {
  text-align: center;
}
.coupon-modal .main-svg {
  margin: 0 auto;
  font-size: 3rem;
}
.coupon-close-btn {
  position: absolute;
  right: 0.5rem;
  top: 0;
  opacity: 1;
}
.coupon-close-btn svg {
  font-size: 2rem;
}

.change-pass-icon {
  margin: 0 auto;
  font-size: 3rem;
  text-align: center;
}
.login-modal-icon {
  font-size: 3rem;
  text-align: center;
}

.fixed-moblie-nav {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 1rem;
  z-index: 999;
  margin: 0;
}
.fixed-moblie-nav .menu-bars {
  padding: 0;
}
.fixed-moblie-nav .mobile-menu-icon i {
  font-size: 1.5rem;
}
.fixed-moblie-nav .account-options {
  position: static;
}
.fixed-moblie-nav .account-options li i,
.fixed-moblie-nav .account-options li svg {
  font-size: 1.5rem;
}
.customer-drawer-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
}
.customer-drawer-main .profile-box-icon {
  /* width: 25%; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  aspect-ratio: 1/1;
  background: #000000;
  border-radius: 50%;
  padding: 10px;
  margin-right: 2rem;
}
.empty-icon {
  font-size: 5rem;
}
.delete-cart-card-btn {
  position: absolute;
  top: -15px;
  right: -15px;
}
.cartcheckout-accordion {
  border-radius: 5px !important;
  font-family: inherit !important;
  background: var(--theme-color-white) !important;
}
.cartcheckout-accordion .ant-collapse-item {
  /* border-radius: 5px !important; */
  /* border-radius: 12px 12px 0 0; */
}
.cartcheckout-accordion .ant-collapse-header {
  /* border-radius: 5px !important; */
  /* background: var(--theme-color-white) !important; */
  border-radius: 12px 12px 0 0;
  flex-direction: row-reverse !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.coupon-main-box {
  /* height: 300px;
  overflow-y: auto; */
}
.coupon-main-box .coupon-card {
  /* height: 200px; */
  border-top: 4px solid var(--theme-color-dark);
  border-radius: 5px;
  padding: 0 !important;
  border-color: var(--theme-color-dark);
}
.coupon-main-box .coupon-card-code {
  width: fit-content;
  background: #000;
  padding: 5px 10px;
  border-radius: 5px;
  color: #fff;
}
.coupon-main-box .coupon-card .coupon-terms-condition .cursor-pointer svg,
.coupon-main-box .view-more svg {
  font-size: 1rem;
}
.coupon-main-box .coupon-card-active {
  /* height: auto;
  overflow-y: auto; */
}

/* height: auto;
  overflow-y: auto; */

.coupon-main-box .apply-coupon-btn {
  height: auto !important;
  background: #000 !important;
  border: none;
  border-top: 1px solid #f3f3f3;
  color: var(--theme-color-dark) !important;
  border-radius: 0 0 5px 5px;
  padding: 0 !important;
  width: 100%;
}
.coupon-main-box .apply-coupon-btn button {
  background: transparent !important;
  border: none;
  border-radius: 0 0 5px 5px;
  width: 100%;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
.coupon-main-box,
.coupon-main-box .coupon-card .terms-show,
.coupon-main-box .coupon-card {
  padding-right: 1rem;
  scrollbar-width: auto;
  scrollbar-color: #a3a3a3 #ffffff;
}

.coupon-main-box::-webkit-scrollbar,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar,
.coupon-main-box .coupon-card::-webkit-scrollbar {
  width: 5px;
}

.coupon-main-box::-webkit-scrollbar-track,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar-track,
.coupon-main-box .coupon-card::-webkit-scrollbar-track {
  background: #ffffff;
}

.coupon-main-box::-webkit-scrollbar-thumb,
.coupon-main-box .coupon-card .terms-show::-webkit-scrollbar-thumb,
.coupon-main-box .coupon-card::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border-radius: 10px;
  border: 3px solid #a3a3a3;
}
:where(.css-dev-only-do-not-override-nllxry).ant-collapse>.ant-collapse-item:last-child, :where(.css-dev-only-do-not-override-nllxry).ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header{
  border-radius: 5px !important;
}
.all-review-section .product-title2{
  height: auto !important;
  font-weight: bold;
}

.all-review-section .item .content-box{
  text-align: left;
}
.all-review-section .item .product-box{
  margin-right: 0 !important;
}
@media (min-width: 993px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
}
@media (max-width: 568px) {
  .all-review-section .item .product-box img{
    height: auto !important;
    aspect-ratio: 1/1;
  }
  .all-review-section .review-card{
  margin-bottom: 1.5rem !important;
  }
  .delete-cart-card-btn {
    position: absolute;
    top: -15px;
    right: -6px;
  }
  .cproduct-card-name {
    padding-right: 2rem;
  }
  .add-instructions-box {
    /* position: absolute;
    width: 100% !important;
    height: 35px;
    bottom: -8px; */
    margin-top: 1rem;
  }
  .add-instructions-box button {
    padding: 0 10px;
    text-align: right;
  }
  .add-instructions-box .add-instructions-input,
  .add-instructions-box button {
    width: 100%;
    height: 100%;
    border-radius: 0 0 5px 0;
  }
  .c-price span {
    gap: 10px;
    display: flex;
    margin-top: 10px;
  }
  .cquantity {
    margin-top: 10px;
  }
  .cquantity label {
    display: none;
  }
  .quantity {
    margin-bottom: 0;
  }
  .quantity__minus,
  .quantity__plus {
    width: 30%;
  }
  .quantity__input {
    width: 50%;
  }
  .add-instructions-modal {
    position: relative !important;
    height: auto;
    width: auto;
    border-radius: 5px;
    margin-top: 1rem;
    left: initial !important;
    bottom: initial !important;
  }
  .add-instructions-modal .add-instructions-input {
    position: relative !important;
    border-radius: 5px;
    padding-right: 1.5rem !important;
  }
  .add-instructions-modal .add-instructions-icons {
    /* bottom: 19px;
  right: 24px; */
    bottom: 3.5px;
  }
  .delivery-slot {
    border-right: none !important;
  }
}
.web-logo-skeleton {
  height: 65px !important;
}
@media (min-width: 568px) {
  .pincode-modal {
    max-width: fit-content !important;
  }
}
